<template>
	<section v-loading="pageLoading" class="staff achievements" ref="staffachievements">
		<el-tabs class="infoTab" v-model="activeName" style="padding: 15px 0; margin-bottom: -15px" @tab-click="changeTab">
			<el-tab-pane v-for="(tab, i) in tabOptions" :label="tab.label" :key="i" :name="tab.name"></el-tab-pane>
		</el-tabs>
		<div style="padding: 0 2.3%" v-if="activeName == '1'">
			<el-tabs class="infoTab secondTabs app-container" v-model="navActive" @tab-click="navTo">
				<el-tab-pane v-for="(nav, i) in navArr" :label="nav.label" :key="i" :name="nav.name"></el-tab-pane>
			</el-tabs>
			<!--<el-button
        v-for="(nav,i) in navArr"
        :key="i"
        :type="navActive==i?'primary':''"
        round
        @click="navTo(i)"
        size="small"
      >{{nav.label}}</el-button>!-->
		</div>
		<div style="padding: 0 2.3%" v-else-if="activeName == '2'">
			<el-tabs class="infoTab secondTabs" v-model="navActive" @tab-click="navTo">
				<el-tab-pane v-for="(nav, i) in navmanageArr" :label="nav.label" :key="i" :name="String(i)"></el-tab-pane>
			</el-tabs>
			<!--<el-button
        v-for="(nav,i) in navmanageArr"
        :key="i"
        :type="navActive==i?'primary':''"
        round
        @click="navTo(i)"
        size="small"
      >{{nav.label}}</el-button>!-->
		</div>
		<el-form
			:model="searchForm"
			inline
			ref="searchForm"
			style="margin: 10px 30px 0 40px"
			v-show="(activeName == '1' && navActive != 1) || activeName != 1"
		>
			<el-form-item label="绩效考核名称">
				<el-select v-model="searchForm.performanceId" size="small" placeholder="请选择绩效考核名称" clearable filterable>
					<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="员工姓名" v-show="activeName != 1 && navActive != 3">
				<el-select v-model="searchForm.staffId" size="small" placeholder="请选择员工" filterable clearable>
					<el-option v-for="item in dutyUsers" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="工号" v-if="activeName == 3">
				<el-input v-model="searchForm.jobNumber" size="small" placeholder="请输入工号"></el-input>
			</el-form-item>
			<el-form-item label="状态" v-if="activeName == 2 && navActive == 0">
				<el-select v-model="searchForm.status" size="small" placeholder="请选择绩效考核状态" clearable>
					<el-option v-for="(status, i) in statusArr" :key="i" :label="status.label" :value="status.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状态" v-if="activeName == 2 && navActive == 1">
				<el-select v-model="searchForm.status" size="small" placeholder="请选择绩效考核状态" clearable>
					<el-option v-for="(status, i) in statusArr1" :key="i" :label="status.label" :value="status.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="年份" v-show="activeName == 1 && navActive != 3">
				<el-date-picker
					v-model="searchForm.year"
					type="year"
					size="small"
					value-format="yyyy"
					placeholder="选择绩效考核年份"
				></el-date-picker>
			</el-form-item>
			<el-form-item label="辅导时间" v-show="navActive == 3">
				<el-date-picker
					v-model="searchForm.timeArr"
					type="datetimerange"
					size="small"
					range-separator="-"
					value-format="yyyy-MM-dd HH:mm:ss"
					:default-time="['00:00:00', '23:59:59']"
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" style="margin: 5px 0 0 5px" size="small" icon="el-icon-search" @click="query">查询</el-button>
			<div style="float: right; margin-right: 0px" v-show="activeName == '2'">
				<el-button
					type="primary"
					size="small"
					@click="$router.push({ name: 'OrganizingAchievements' })"
					v-if="activeName == 2 && navActive == 1 && $store.state.app.orgIds && $store.state.app.orgIds.length > 0"
					>组织绩效</el-button
				>
				<el-button type="primary" size="small" icon="el-icon-upload" @click="dialogImportVisible = true">导入</el-button>
				<el-button type="primary" size="small" icon="el-icon-download" @click="Download">导出</el-button>
			</div>
		</el-form>
		<el-table :data="tableData" stripe header-row-class-name="tableClass" v-show="activeName == '1' && navActive == 0">
			<el-table-column v-for="col in columns" align="center" :prop="col.id" :key="col.id" :label="col.label">
				<template slot-scope="{ row }">
					<div v-if="col.id == 'statusName'" :style="`color:${returnStatusColor(row.status)}`" @click="openNote(row)">
						{{ row.statusName }}
					</div>
					<div v-else-if="col.id == 'performanceScoreUser'">{{ row.status == '10' ? row.performanceScoreUser : '' }}</div>
					<div v-else>{{ row[col.id] }}</div>
				</template>
			</el-table-column>
			<el-table-column label="评价说明" prop="assessmentNote" align="center">
				<template slot-scope="{ row }">
					<div class="noteBox">{{ row.assessmentNote }}</div>
				</template>
			</el-table-column>
			<el-table-column label="附件" align="center" min-width="180px">
				<template slot-scope="{ row }" v-if="row.contentFileUrlList != null && row.contentFileUrlList.length > 0">
					<el-button class="filebtn" type="text" @click="openFile(row.contentFileUrlList[0].url)">{{
						row.contentFileUrlList[0].name
					}}</el-button>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="{ row }" v-if="row.performanceScoreUser && row.status == '10'">
					<el-button type="text" @click="levelLook(row)">查看成绩</el-button>
				</template>
			</el-table-column>
		</el-table>
		<achievementplan :tableData="tableData" @opendig="opendig" v-if="activeName == '1' && navActive == 1"></achievementplan>
		<achievementself :tableData="tableData" @opendig="opendig" v-if="activeName == '1' && navActive == 2"></achievementself>
		<achievementcoaching
			:searchForm="searchForm"
			:table="table"
			@pagegive="pagegive"
			@loading="loading"
			ref="coaching"
			v-if="activeName == 3"
		></achievementcoaching>
		<manageplan
			:searchForm="searchForm"
			:table="table"
			@pagegive="pagegive"
			@loading="loading"
			ref="manageplan"
			v-if="activeName == '2' && navActive == 0"
		></manageplan>
		<performanceRating
			:searchForm="searchForm"
			:table="table"
			@pagegive="pagegive"
			@loading="loading"
			ref="performanceRating"
			v-if="activeName == 2 && navActive == 1"
		></performanceRating>
		<assesstutorship
			@loading="loading"
			:searchForm="searchForm"
			v-if="activeName == '1' && navActive == '3'"
			ref="tutorship"
			:table="table"
			@pagegive="pagegive"
		></assesstutorship>
		<el-pagination
			@size-change="sizeChange"
			@current-change="currentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 40, 50, 80, 100]"
			:page-size="table.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.totalNum"
		></el-pagination>
		<el-dialog center :title="navActive == '1' ? '绩效计划填写' : '绩效自评'" :visible.sync="plandig" width="40%" class="plandig">
			<div class="losetitle">
				<strong
					>{{ navActive == 1 ? planForm.staffName : planSelfForm.staffName }}({{
						navActive == 1 ? planForm.jobNumber : planSelfForm.jobNumber
					}})</strong
				>
				<el-tag style="margin-left: 10px; transform: translateY(-2px)" size="small">{{
					navActive == 1 ? planForm.statusName : planSelfForm.statusName
				}}</el-tag>
				<div style="overflow: hidden">
					<div class="fl" style="line-height: 20px">
						部门：{{ navActive == 1 ? planForm.orgName : this.planSelfForm.orgName }} 职位：{{
							navActive == 1 ? planForm.officeName : this.planSelfForm.officeName
						}}
					</div>
					<div class="fr" style="line-height: 20px">
						直接汇报人：{{ navActive == 1 ? planForm.repObjName : this.planSelfForm.repObjName }}
					</div>
				</div>
			</div>
			<p class="border-line"></p>
			<el-form :model="planForm" ref="planForm" v-if="navActive == 1" label-width="120px" style="padding: 10px 2%">
				<el-form-item label="直接汇报人" prop="upStaffId" :rules="rule">
					<el-select v-model="planForm.upStaffId" style="width: 90%" :disabled="!boolean" filterable>
						<el-option
							v-for="item in dutyUsers"
							:key="item.value"
							:disabled="!$store.state.app.companyPrin && $store.state.app.staffId == item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="绩效文件"
					style="position: relative"
					:prop="navActive == '1' ? 'contentFileUrlList' : 'selfFileUrlList'"
					:class="
						(navActive != '1' && planForm.contentFileUrlList && planForm.contentFileUrlList.length > 0) ||
						(navActive == '1' && planForm.assessFileUrlList && planForm.assessFileUrlList.length > 0)
							? 'hasfile'
							: ''
					"
					:rules="{ required: true, message: '请上传绩效文件', type: 'array', trigger: 'change' }"
				>
					<el-checkbox-group v-model="planForm.contentFileUrlList" v-if="navActive == '1'"></el-checkbox-group>
					<el-checkbox-group v-model="planForm.selfFileUrlList" v-else></el-checkbox-group>
					<el-upload
						:disabled="!boolean"
						class="upload-demo"
						action
						accept=".xls,.xlsx,.XLS,.XLSX"
						:limit="1"
						:file-list="navActive == '1' ? planForm.contentFileUrlList : planForm.selfFileUrlList"
						:show-file-list="true"
						:http-request="exportExcel"
						:on-exceed="handleExceed"
						:on-preview="lookordownload"
						:on-remove="remove"
					>
						<el-button
							v-if="
								(navActive == '1' && planForm.contentFileUrlList && planForm.contentFileUrlList.length == 0) ||
								(navActive == '2' && planForm.selfFileUrlList && planForm.selfFileUrlList.length == 0)
							"
							type="primary"
							:disabled="!boolean"
							>上传文件</el-button
						>
						<div
							slot="tip"
							v-if="
								(navActive == '1' && planForm.contentFileUrlList && planForm.contentFileUrlList.length == 0) ||
								(navActive == '2' && planForm.selfFileUrlList && planForm.selfFileUrlList.length == 0)
							"
							class="el-upload__tip"
						>
							文件格式为XLS/XLSX，大小在5MB内
						</div>
					</el-upload>
					<div class="preview_top" v-show="boolean">
						<img :src="require('../../../public/static/images/fj.png')" />
						<span v-if="navActive != '1'">{{
							planForm.contentFileUrlList && planForm.contentFileUrlList[0] && planForm.contentFileUrlList[0].name
						}}</span>
						<span v-if="navActive == '1'">{{
							planForm.assessFileUrlList && planForm.assessFileUrlList[0] && planForm.assessFileUrlList[0].name
						}}</span>
						<div style="margin-left: 10px">
							<el-button type="text" size="small" @click="look">预览</el-button>
							<el-button type="text" size="small" style="margin-left: -5px" @click="downloadtemplate">下载</el-button>
						</div>
					</div>
				</el-form-item>
				<p style="padding: 10px 0 0 120px; line-height: 20px" v-show="activeName == '1' && navActive == '1'">
					<i class="el-icon-warning-outline" style="color: #ff604d; margin-right: 8px"></i>
					<span>考核说明:&nbsp;</span>
					<span class="shuoming">{{ planForm.assessNote }}</span>
				</p>
			</el-form>
			<span slot="footer" v-if="boolean">
				<el-button @click="plandig = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible2" width="530px" top="28vh" :show-close="false" class="submig_dia" center>
			<div class="submig_dia_title" slot="title" v-if="this.currentForm.performanceName.length > 11">
				<div>{{ this.currentForm.performanceName.substr(0, 11) }}</div>
				<div>{{ this.currentForm.performanceName.substr(12, this.currentForm.performanceName.length - 1) }}</div>
			</div>
			<div class="submig_dia_title" slot="title" v-else>
				<div></div>
				<div>{{ this.currentForm.performanceName }}</div>
			</div>
			<p class="submit_text" v-if="currentForm.performanceScoreUser == 'S' || currentForm.performanceScoreUser == 'A'">
				恭喜您的绩效等级为<span>{{ currentForm.performanceScoreUser }}</span>
			</p>
			<p class="submit_text" v-else>您的绩效等级为{{ currentForm.performanceScoreUser }}</p>
		</el-dialog>
		<ImportCom
			:dialogImportVisible.sync="dialogImportVisible"
			:importUrl="importUrl + (Number(navActive) + 1)"
			:type="'achievementsmanagement'"
			:params="searchForm"
			:navActive="navActive"
			@handleSearch="query"
		/>
	</section>
</template>
<script>
import achievementplan from './components/achievements/achievementPlan';
import achievementself from './components/achievements/achievementSelf';
import achievementcoaching from './components/achievements/achievementCoaching';
import manageplan from './components/achievements/managePlan';
import performanceRating from './components/achievements/performanceRating';
import assesstutorship from './components/achievements/assesstutorship';
import ImportCom from '@/components/import';
import { selectPrincipallist } from '@/api/recruitment_allocation';
import { performanceStaffList, performanceNameChoice, assessStaffWrite, assessStaffEvaluate } from '@/api/performance';
export default {
	components: {
		achievementplan,
		achievementself,
		achievementcoaching,
		ImportCom,
		manageplan,
		performanceRating,
		assesstutorship
	},
	data() {
		const upStaffId = (rule, value, callback) => {
			if (!value || value == 0) {
				return callback(new Error('请选择直接汇报人'));
			}
			callback();
		};

		return {
			activeName: '1',
			navActive: '1',
			pageLoading: false,
			plandig: false,
			dialogImportVisible: false,
			dialogVisible2: false,
			boolean: true,
			importUrl: '/v1/performance/plan/evaluate/import?params=',
			tabOptions: [
				{ name: '1', label: '我的绩效' },
				{ name: '2', label: '绩效管理' },
				{ name: '3', label: '绩效辅导' }
			],
			navArr: [
				{ name: '0', label: '绩效成绩' },
				{ name: '1', label: '绩效计划' },
				{ name: '2', label: '绩效自评' },
				{ name: '3', label: '绩效辅导' }
			],
			navmanageArr: [
				{ name: '1', label: '绩效计划' },
				{ name: '2', label: '绩效评定' }
			],
			searchForm: {
				performanceId: '',
				year: '',
				name: '',
				jobNumber: '',
				staffId: '',
				status: '',
				timeArr: []
			},
			currentForm: { performanceName: '', performanceScoreUser: '' },
			table: { pageNo: 1, pageSize: 20, totalNum: 0 },
			options: [],
			dutyUsers: [],
			statusArr: [
				{ label: '待审批', value: '2' },
				{ label: '审批通过', value: '3' },
				{ label: '审批失败', value: '4' }
			],
			statusArr1: [
				{ label: '待评定', value: '6' },
				{ label: '待确认', value: '8' },
				{ label: '评定失败', value: '7' },
				{ label: '确认失败', value: '9' }
			],
			columns: [
				{ id: 'performanceName', label: '绩效考核名称' },
				{ id: 'assessCycle', label: '绩效考核周期' },
				{ id: 'assessStartTime', label: '开始时间' },
				{ id: 'assessEndTime', label: '结束时间' },
				// { id: "performanceScoreUser", label: "绩效等级" },
				{ id: 'repObjName', label: '直属汇报人' },
				{ id: 'statusName', label: '状态' }
			],
			planForm: { upStaffId: '' },
			planSelfForm: { upStaffId: '', selfFileUrlList: [] },
			rule: { required: true, validator: upStaffId, trigger: 'change' },
			tableData: []
		};
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem('obj', JSON.stringify({ activeName: this.activeName, navActive: this.navActive }));
		next();
	},
	mounted() {
		if (sessionStorage.getItem('obj')) {
			this.activeName = JSON.parse(sessionStorage.getItem('obj')).activeName;
			this.navActive = JSON.parse(sessionStorage.getItem('obj')).navActive;
		}
		selectPrincipallist({}).then((res) => {
			if (Array.isArray(res)) {
				res.map((it) => {
					this.dutyUsers.push({
						label: `${it.staffName} ${it.officeName ? `(${it.officeName})` : ''}`,
						value: it.staffId
					});
				});
			}
		});
		performanceNameChoice({}).then((res) => {
			if (res) {
				this.options = res.list;
			}
		});
		if (Number(this.navActive) < 3) {
			this.getList();
		}
	},
	methods: {
		openFile(url) {
			window.open(url);
		},
		navTo() {
			if (Number(this.navActive) < 3) {
				this.table.pageNo = 1;
				this.$refs.searchForm.resetFields('searchForm');
				this.searchForm = {
					performanceId: '',
					year: '',
					name: '',
					jobNumber: '',
					staffId: '',
					status: '',
					timeArr: []
				};
				this.query();
			}
		},
		query() {
			this.table.pageNo = 1;
			if (this.activeName == '1' && Number(this.navActive) < 3) {
				this.getList();
			} else if (this.activeName == '1' && Number(this.navActive) == 3) {
				if (this.$refs.tutorship) {
					this.$refs.tutorship.getTutorshipList();
				}
			} else if (this.activeName == '2' && Number(this.navActive) == 0) {
				if (this.$refs.manageplan) {
					this.$refs.manageplan.getPerformancePlan();
				}
			} else if (this.activeName == '2' && Number(this.navActive) == 1) {
				if (this.$refs.performanceRating) {
					this.$refs.performanceRating.getPerformancePlan();
				}
			} else if (this.activeName == '3') {
				if (this.$refs.coaching) {
					this.$refs.coaching.getTutorshipList();
				}
			}
		},
		returnStatusColor(status) {
			// eslint-disable-next-line default-case
			switch (Number(status)) {
				case 1:
					return '#409EFF';
				case 2:
					return '#FEB840';
				case 3:
					return '#64C940';
				case 4:
					return '#FF604D';
				case 5:
					return '#40CEFC';
				case 6:
					return '#F38A41';
				case 7:
					return '#FF604D';
				case 8:
					return '#F38A41';
				case 9:
					return '#FF604D';
				case 10:
					return '#64C940';
			}
		},
		getList() {
			const params = {
				...this.searchForm,
				type: Number(this.navActive) + 1,
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			this.pageLoading = true;
			performanceStaffList(params).then((res) => {
				this.tableData = res.list;
				this.table.totalNum = res.totalNum;
				this.pageLoading = false;
			});
		},
		Download() {
			const params = {
				...this.searchForm,
				type: Number(this.navActive) + 1,
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			const url = `${location.origin}/v1/performance/plan/evaluate/export?params=${encodeURIComponent(JSON.stringify(params))}`;

			window.open(url);
		},
		downloadtemplate() {
			// eslint-disable-next-line init-declarations
			let url;

			if (this.navActive == '1') {
				url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(
					JSON.stringify({
						fileName: this.planForm.assessFileUrlList[0].name,
						bucketName: this.planForm.assessFileUrlList[0].bucketName,
						key: this.planForm.assessFileUrlList[0].key,
						httpUrl: this.planForm.assessFileUrlList[0].httpUrl
					})
				)}`;
			} else {
				url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(
					JSON.stringify({
						fileName: this.planForm.contentFileUrlList[0].name,
						bucketName: this.planForm.contentFileUrlList[0].bucketName,
						key: this.planForm.contentFileUrlList[0].key,
						httpUrl: this.planForm.contentFileUrlList[0].httpUrl
					})
				)}`;
			}
			window.open(url);
		},
		levelLook(row) {
			this.currentForm = row;
			this.dialogVisible2 = true;
		},
		look() {
			if (this.navActive == 1) {
				window.open(`http://view.officeapps.live.com/op/view.aspx?src=${this.planForm.assessFileUrlList[0].url}`);
			} else {
				window.open(`http://view.officeapps.live.com/op/view.aspx?src=${this.planSelfForm.contentFileUrlList[0].url}`);
			}
		},
		lookordownload() {
			this.$confirm('请选择您想要的操作', '提示', {
				confirmButtonText: '下载',
				cancelButtonText: '预览',
				distinguishCancelAndClose: true,
				type: 'info'
			})
				.then(() => {
					if (this.navActive != 1) {
						window.open(this.planSelfForm.selfFileUrlList[0].url);
					} else {
						window.open(this.planForm.contentFileUrlList[0].url);
					}
				})
				.catch((action) => {
					if (action === 'cancel') {
						if (this.navActive != 1) {
							window.open(`http://view.officeapps.live.com/op/view.aspx?src=${this.planSelfForm.selfFileUrlList[0].url}`);
						} else {
							window.open(`http://view.officeapps.live.com/op/view.aspx?src=${this.planForm.contentFileUrlList[0].url}`);
						}
					}
				});
		},
		openNote(row) {
			if (row.status == '3' || row.status == '4' || row.status == '7' || row.status == '9') {
				this.$alert(
					// eslint-disable-next-line no-nested-ternary
					`${Number(row.status) > 8 ? row.confirmNote : Number(row.status) > 6 ? row.assessmentNote : row.approveNote}`,
					'状态说明',
					{
						confirmButtonText: '确定'
					}
				);
			}
		},
		opendig(obj) {
			this.boolean = obj.boolean;
			if (this.navActive == 1) {
				this.planForm = JSON.parse(JSON.stringify(obj.row));
				if (this.planForm.upStaffId == 0) {
					this.planForm.upStaffId = '';
				}
				// eslint-disable-next-line no-eq-null
				if (this.planForm.contentFileUrlList == null) {
					this.planForm.contentFileUrlList = [];
				}
				this.plandig = true;
				if (this.$refs.planForm !== undefined) {
					this.$refs['planForm'].clearValidate();
					this.$refs['planForm'].resetFields();
				}
			} else {
				this.planSelfForm = JSON.parse(JSON.stringify(obj.row));
				if (this.planSelfForm.upStaffId == 0) {
					this.planSelfForm.upStaffId = '';
				}
				// eslint-disable-next-line no-eq-null
				if (this.planSelfForm.contentFileUrlList == null) {
					this.planSelfForm.contentFileUrlList = [];
				}
				this.planSelfForm.selfFileUrlList = [];
				if (!JSON.parse(JSON.stringify(obj.boolean)) && this.navActive != 1) {
					this.planSelfForm.selfFileUrlList = this.planSelfForm.contentFileUrlList;
				}
				this.plandig = true;
				if (this.$refs.planSelfForm !== undefined) {
					this.$refs['planSelfForm'].clearValidate();
					this.$refs['planSelfForm'].resetFields();
				}
			}
		},
		loading(boolean) {
			this.pageLoading = boolean;
		},
		submit() {
			if (this.navActive == 1) {
				this.$refs.planForm.validate((valid) => {
					if (valid) {
						this.$confirm('您确认要提交当前绩效计划?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							const params = {
								userPerformanceId: this.planForm.id,
								contentFileUrl: JSON.stringify(this.planForm.contentFileUrlList),
								upStaffId: this.planForm.upStaffId
							};

							assessStaffWrite(params).then((res) => {
								if (res._responseStatusCode == 0) {
									this.$message.success('绩效计划已提交');
									this.getList();
									this.plandig = false;
								}
							});
						});
					}
				});
			} else {
				this.$refs.planSelfForm.validate((valid) => {
					if (valid) {
						this.$confirm('您确认当前计划已自评完毕?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							const params = {
								userPerformanceId: this.planSelfForm.id,
								contentFileUrl: JSON.stringify(this.planSelfForm.contentFileUrlList),
								upStaffId: this.planSelfForm.upStaffId
							};

							const fileList = this.planSelfForm.selfFileUrlList;

							assessStaffEvaluate({ ...params, tutorshipStaffId: params.upStaffId, contentFileUrl: JSON.stringify(fileList) }).then(
								(res) => {
									if (res._responseStatusCode == 0) {
										this.$message.success('绩效自评已提交');
										this.getList();
										this.plandig = false;
									}
								}
							);
						});
					}
				});
			}
		},
		exportExcel(event) {
			this.errShow = false;
			if (!event.file || !event.file.name) {
				this.$message.error('文件有误');
				return;
			}
			if (
				['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].indexOf(event.file.type) == -1 &&
				!event.file.name.includes('.xl')
			) {
				this.$message.error('请上传正确Excel文件');
				return;
			}
			const params = new FormData();

			params.append('imgFile', event.file);
			const loading = this.$loading({
				lock: true,
				text: '正在努力导入......',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loadClass'
			});

			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.$axios
				.post('/v1/common/upload/file', params, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					if (res) {
						loading.spinner = 'el-icon-success';
						loading.text = '导入成功';
						this.planForm.contentFileUrlList = [];
						this.planForm.contentFileUrlList.push({
							name: res.fileName,
							size: '',
							url: res.httpUrl,
							bucketName: res.bucketName,
							key: res.key
						});
						setTimeout(() => {
							loading.close();
						}, 1000);
						this.dialogImportVisible = false;
						// this.query();
					}
				});
		},
		UploadFile(event) {
			this.errShow = false;
			if (!event.file || !event.file.name) {
				this.$message.error('文件有误');
				return;
			}
			if (
				['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].indexOf(event.file.type) == -1 &&
				!event.file.name.includes('.xl')
			) {
				this.$message.error('请上传正确Excel文件');
				return;
			}
			const params = new FormData();

			params.append('imgFile', event.file);
			const loading = this.$loading({
				lock: true,
				text: '正在努力导入......',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loadClass'
			});

			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.$axios
				.post('/v1/common/upload/file', params, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					if (res) {
						loading.spinner = 'el-icon-success';
						loading.text = '导入成功';
						this.planSelfForm.selfFileUrlList = [];
						this.planSelfForm.selfFileUrlList.push({
							name: res.fileName,
							size: '',
							url: res.httpUrl,
							bucketName: res.bucketName,
							key: res.key
						});
						setTimeout(() => {
							loading.close();
						}, 1000);
						this.dialogImportVisible = false;
						this.query();
					}
				});
		},
		remove(file) {
			if (this.navActive == 1) {
				this.planForm.contentFileUrlList.splice(file);
			} else {
				this.planSelfForm.selfFileUrlList.splice(file);
			}
			console.log(this.planSelfForm);
		},
		handleExceed() {
			this.$message.warning('只允许上传单份绩效文件');
		},
		changeTab() {
			this.navActive = '0';
			this.query();
		},
		pagegive(totalNum) {
			this.table.totalNum = totalNum;
		},
		sizeChange(val) {
			this.table.pageSize = val;
			this.query();
		},
		currentChange(val) {
			this.table.pageNo = val;
			this.query();
		}
	}
};
</script>

<style lang="scss">
.el-message-box__message {
	> p {
		width: 100%;
		// padding: 0 6%;
		overflow: auto;
		max-height: 800px;
		word-break: break-word;
	}
}
.shuoming {
	width: 75%;
	line-height: 20px;
	vertical-align: top;
	display: inline-block;
}
.achievements {
	overflow: hidden;
	background-color: #fff;
	.app-container {
		padding: 0;
	}
	.tableClass th {
		background-color: #f0f8ff;
		color: #484848;
	}
	.hasfile {
		margin-bottom: -25px;
		.el-upload-list {
			transform: translateY(-40px);
		}
		.el-form-item__error {
			bottom: 15px;
			top: auto;
		}
	}
	.submig_dia {
		.el-dialog__header {
			padding: 0;
		}
		.submig_dia_title {
			height: 150px;
			background: url('/static/images/evaluate.png') no-repeat center top;
			background-size: 100% auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			div {
				font-family: HYk2gj;
				font-size: 36px;
				font-weight: bold;
				color: #ffffff;
				letter-spacing: 6px;
				line-height: 22px;
				margin: 10px 20px;
			}
		}
		.submit_text {
			font-family: HYk2gj;
			font-size: 24px;
			color: #392377;
			letter-spacing: 0;
			text-align: center;
			line-height: 50px;
			span {
				color: #ff5d4e;
				margin-left: 5px;
				font-size: 30px;
			}
		}
	}
	.el-message-box__status + .el-message-box__message {
		padding-left: 15px;
	}
	.el-upload-list {
		.el-upload-list__item {
			width: 50%;
			padding: 5px;
			background-color: #f4f4f5;
			label i,
			.el-icon-close {
				transform: translate(-5px, 5px);
			}
		}
		.el-upload-list__item:hover {
			background-color: #f5f7fa;
		}
	}
	.noteBox {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		padding: 0 2px;
		max-height: 100px;
	}
	.el-table {
		width: 96%;
		margin: 0px 2% 5px;
	}
	.el-pagination {
		float: right;
		margin-top: 20px;
		margin-right: 25px;
	}
	.plandig {
		.el-dialog__footer {
			text-align: center;
		}
		.preview_top {
			display: -webkit-box;
			border-radius: 4px;
			transform: translate(-5px, -25px);
			width: 280px;
			margin-top: 20px;
			text-align: center;
			img {
				width: 40px;
				height: 40px;
				margin-right: 5px;
				vertical-align: top;
			}
			> div {
				margin-left: 10px;
				width: 200px;
				display: flex;
				transform: translateY(-10px);
				.el-button {
					width: 35px;
				}
			}
			span {
				line-height: 40px;
				height: 40px;
				display: block;
				white-space: nowrap;
				text-align: left;
				max-width: 255px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
