<template>
  <section>
    <el-table :data="tableData" stripe header-row-class-name="tableClass">
      <el-table-column
        v-for="col in columns"
        align="center"
        :prop="col.id"
        :key="col.id"
        :min-width="col.minWidth"
        :label="col.label"
      >
        <template slot-scope="{row}">
          <el-button
            v-if="col.id=='contentName'&&row.contentFileUrlList"
            type="text"
            class="filebtn"
            @click="openFile(row.contentFileUrlList[0]?row.contentFileUrlList[0]:'')"
          >{{row.contentFileUrlList[0]?row.contentFileUrlList[0].name:''}}</el-button>
          <div
            :style="`color:${returnStatusColor(row.status)}`"
            v-else-if="col.id=='statusName'"
            @click="openNote(row)"
          >{{row.statusName}}</div>
          <div v-else>{{row[col.id]}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row}">
          <el-button
            type="text"
            v-if="row.startStatus=='1'&&row.selfEvaluationStatus=='1'&&row.status!='6'&&row.status!='8'"
            @click="opendig(row,true)"
          >自评</el-button>
          <el-button type="text" v-else-if="row.status!='5'" @click="opendig(row,false)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog center title="绩效自评" :visible.sync="planselfdig" width="40%" class="plandig">
      <div class="losetitle">
        <strong>{{planSelfForm.staffName }}({{planSelfForm.jobNumber}})</strong>
        <el-tag
          style="margin-left:10px;transform: translateY(-2px);"
          size="small"
        >{{planSelfForm.statusName}}</el-tag>
        <div style="overflow:hidden">
          <div
            class="fl"
            style="line-height:20px"
          >部门：{{this.planSelfForm.orgName }} 职位：{{this.planSelfForm.officeName}}</div>
          <div class="fr" style="line-height:20px">直接汇报人：{{this.planSelfForm.repObjName}}</div>
        </div>
      </div>
      <el-form :model="planSelfForm" ref="planSelfForm" label-width="120px" style="padding:10px 2%">
        <el-form-item label="直接汇报人" prop="upStaffId" :rules="rule">
          <el-select
            v-model="planSelfForm.upStaffId"
            style="width:90%"
            :disabled="!boolean"
            filterable
          >
            <el-option
              v-for="item in $parent.dutyUsers"
              :key="item.value"
              :disabled="!$store.state.app.companyPrin&&($store.state.app.staffId==item.value)"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="绩效自评文件"
          style="position:relative"
          prop="selfFileUrlList"
          :class="planSelfForm.contentFileUrlList&&planSelfForm.contentFileUrlList.length>0?'hasfile':''"
          :rules="{required:true,message:'请上传绩效自评文件',trigger:'change'}"
        >
          <el-checkbox-group v-model="planSelfForm.selfFileUrlList"></el-checkbox-group>
          <el-upload
            :disabled="!boolean"
            class="upload-demo"
            action
            accept=".xls, .xlsx, .XLS, .XLSX"
            :limit="1"
            :file-list="planSelfForm.selfFileUrlList"
            :show-file-list="true"
            :http-request="UploadFile"
            :on-exceed="handleExceed"
            :on-preview="lookordownload"
            :on-remove="remove"
          >
            <el-button
              v-show="planSelfForm.selfFileUrlList.length===0"
              type="primary"
              :disabled="!boolean"
            >上传文件</el-button>
            <div
              slot="tip"
              v-show="planSelfForm.selfFileUrlList.length===0"
              class="el-upload__tip"
            >文件格式为XLS/XLSX，大小在5MB内</div>
          </el-upload>
          <div class="preview_top" v-show="boolean">
            <img :src="require('../../../../../public/static/images/fj.png')" />
            <span>{{planSelfForm.contentFileUrlList&&planSelfForm.contentFileUrlList[0]&&planSelfForm.contentFileUrlList[0].name}}</span>
            <div style="margin-left:10px">
              <el-button type="text" size="small" @click="look">预览</el-button>
              <el-button
                type="text"
                size="small"
                style="margin-left:5px"
                @click="downloadtemplate"
              >下载</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" v-if="boolean">
        <el-button @click=" planselfdig= false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { assessStaffEvaluate } from '@/api/performance';
export default {
  props: {
    tableData: {
      required: true,
      type: Array
    }
  },
  data() {
    const upStaffId = (rule, value, callback) => {
      if (!value || value == 0) {
        return callback(new Error('请选择直接汇报人'));
      }
        callback();

    };

    return {
      columns: [
        { id: 'performanceName', label: '绩效考核名称' },
        { id: 'assessCycle', label: '绩效计划周期' },
        { id: 'assessStartTime', label: '开始时间' },
        { id: 'assessEndTime', label: '结束时间' },
        { id: 'statusName', label: '状态' },
        { id: 'contentName', label: '附件', minWidth: '180px' }
        // { id:"operation",label:"操作",text:"自评"}
      ],
      boolean: true,
      planselfdig: false,
      dutyUsers: [],
      rule: { required: true, validator: upStaffId, trigger: 'change' },
      planSelfForm: {
        upStaffId: '',
        selfFileUrlList: []
      }
    };
  },
  methods: {
    opendig(row, boolean) {
      row.selfFileUrlList = [];
      this.planSelfForm = JSON.parse(JSON.stringify(row));
      if (this.planSelfForm.upStaffId == 0) {this.planSelfForm.upStaffId = '';}
      if (this.planSelfForm.contentFileUrlList == null) {this.planSelfForm.contentFileUrlList = [];}
      if (!boolean) {this.planSelfForm.selfFileUrlList = this.planSelfForm.contentFileUrlList;}
        this.boolean = boolean;
        this.planselfdig = true;
      if (this.$refs.planSelfForm !== undefined) {
        this.$refs['planSelfForm'].clearValidate();
        this.$refs['planSelfForm'].resetFields();
      }
    },
    returnStatusColor(status) {
      switch (Number(status)) {
        case 1:
          return '#409EFF';
        case 2:
          return '#FEB840';
        case 3:
          return '#64C940';
        case 4:
          return '#FF604D';
        case 5:
          return '#40CEFC';
        case 6:
          return '#F38A41';
        case 7:
          return '#FF604D';
        case 8:
          return '#F38A41';
        case 9:
          return '#FF604D';
        case 10:
          return '#64C940';
      }
    },
    openNote(row) {
      if (row.status == '7') {
        this.$alert(
          `${Number(row.status) > 6 ? row.assessmentNote : row.approveNote}`,
          '状态说明',
          {
            confirmButtonText: '确定'
          }
        );
      }
    },
    openFile(file) {
      const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(JSON.stringify({fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl}))}`;

      window.open(url);
    },
    submit() {
      this.$refs.planSelfForm.validate((valid) => {
        if (valid) {
          this.$confirm('您确认当前计划已自评完毕?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const params = {
              userPerformanceId: this.planSelfForm.id,
              contentFileUrl: JSON.stringify(
                this.planSelfForm.contentFileUrlList
              ),
              upStaffId: this.planSelfForm.upStaffId
            };

            const fileList = this.planSelfForm.selfFileUrlList;

            assessStaffEvaluate({
              ...params,
              tutorshipStaffId: params.upStaffId,
              contentFileUrl: JSON.stringify(fileList)
            }).then((res) => {
              if (res._responseStatusCode == 0) {
                this.$message.success('绩效自评已提交');
                this.$parent.getList();
                this.planselfdig = false;
              }
            });
          });
        }
      });
    },
    Download() {
        const params = {
        ...this.searchForm,
        type: Number(this.navActive) + 1,
        pageNo: this.table.pageNo,
        pageSize: this.table.pageSize
        };

        const url = `${
        location.origin
        }/v1/performance/plan/evaluate/export?params=${encodeURIComponent(
        JSON.stringify(params)
        )}`;

        window.open(url);
    },
    downloadtemplate() {
        if (this.navActive == '1') {
        window.open(this.planForm.assessFileUrlList[0].url);
        } else {
        window.open(this.planForm.contentFileUrlList[0].url);
        }
    },
    levelLook(row) {
        this.currentForm = row;
        this.dialogVisible2 = true;
    },
    look() {
        if (this.navActive == 1) {
        window.open(
            `http://view.officeapps.live.com/op/view.aspx?src=${this.planForm.assessFileUrlList[0].url}`
        );
        } else {
        window.open(
            `http://view.officeapps.live.com/op/view.aspx?src=${this.planSelfForm.contentFileUrlList[0].url}`
        );
        }
    },
    lookordownload() {
        this.$confirm('请选择您想要的操作', '提示', {
        confirmButtonText: '下载',
        cancelButtonText: '预览',
        distinguishCancelAndClose: true,
        type: 'info'
        })
        .then(() => {
            if (this.navActive != 1) {
            window.open(this.planSelfForm.selfFileUrlList[0].url);
            } else {
            window.open(this.planForm.contentFileUrlList[0].url);
            }
        })
        .catch((action) => {
            if (action === 'cancel') {
            if (this.navActive != 1) {
                window.open(
                `http://view.officeapps.live.com/op/view.aspx?src=${this.planSelfForm.selfFileUrlList[0].url}`
                );
            } else {
                window.open(
                `http://view.officeapps.live.com/op/view.aspx?src=${this.planForm.contentFileUrlList[0].url}`
                );
            }
            }
        });
    },
    UploadFile(event) {
        this.errShow = false;
        if (!event.file || !event.file.name) {
        this.$message.error('文件有误');
        return;
        }
        if (
        [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel'
        ].indexOf(event.file.type) == -1 &&
        !event.file.name.includes('.xl')
        ) {
        this.$message.error('请上传正确Excel文件');
        return;
        }
        const params = new FormData();

        params.append('imgFile', event.file);
        const loading = this.$loading({
        lock: true,
        text: '正在努力导入......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: 'loadClass'
        });

        const type =
        'multipart/form-data;boundary=--------------------56423498738365';

        this.$axios
        .post('/v1/common/upload/file', params, {
            headers: { 'Content-Type': type }
        })
        .then((res) => {
            if (res) {
            loading.spinner = 'el-icon-success';
            loading.text = '导入成功';
            this.planSelfForm.selfFileUrlList = [];
            this.planSelfForm.selfFileUrlList.push({
                name: res.fileName,
                size: '',
                url: res.httpUrl,
                bucketName: res.bucketName,
                key: res.key
            });
            setTimeout(() => {
                loading.close();
            }, 1000);
            this.dialogImportVisible = false;
            }
        });
    },
    remove(file) {
        this.planSelfForm.selfFileUrlList.splice(file);
    },
    handleExceed() {
        this.$message.warning('只允许上传单份绩效文件');
    }
  }
};
</script>

<style>
</style>
