<template>
  <div>
    <el-table :data="tableData" stripe header-row-class-name="tableClass">
      <el-table-column
        v-for="col in columns"
        align="center"
        :prop="col.id"
        :key="col.id"
        :min-width="col.minWidth"
        :label="col.label"
      >
        <template slot-scope="{row}">
          <div v-if="col.id=='staffName'">{{row.staffName}}({{row.jobNumber}})</div>
          <div v-else-if="col.id=='assessmentNote'" class="noteBox">{{row.assessmentNote}}</div>
          <div :style="`color:${returnStatusColor(row.status)}`" v-else-if="col.id=='statusName'" @click="openNote(row)">{{row.statusName}}</div>
          <el-button
            v-else-if="col.id=='contentName'&&row.contentFileUrlList"
            type="text"
            class="filebtn"
            @click="openFile(row.contentFileUrlList[0]?row.contentFileUrlList[0]:'')"
          >{{row.contentFileUrlList[0]?row.contentFileUrlList[0].name:''}}</el-button>
          <div v-else>{{row[col.id]}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row}" >
          <el-button type="text" v-if="row.status==2"  @click="Aduit(true,row)">审批</el-button>
          <el-button type="text"  v-if="row.status!=2" @click="Aduit(false,row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="绩效审批" :visible.sync="plandig" center width="40%" class="plandig">
      <div class="losetitle">
        <strong>{{ planForm.staffName }}({{planForm.jobNumber}})</strong>
        <div style="overflow:hidden">
          <div class="fl" style="line-height:20px">部门：{{ planForm.orgName }} 职位：{{ planForm.officeName }}</div>
          <div class="fr" style="line-height:20px">直接汇报人：{{ planForm.repObjName }}</div>
        </div>
      </div>
      <p class="border-line"></p>
      <el-form :model="planForm" ref="planForm" label-width="100px" style="padding:10px 2%">
        <el-form-item label="审批状态">
          <el-radio-group v-model="planForm.approvalStatus" :disabled="!boolean" @change="change">
            <el-radio label="1">通过</el-radio>
            <el-radio label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审批说明" prop="approveNote" v-if="planForm.approvalStatus=='1'" style="margin-bottom:5px">
          <el-input v-model="planForm.approveNote" maxlength="999" :disabled="!boolean" show-word-limit type="textarea" :rows="4" placeholder="请输入审批说明"></el-input>
        </el-form-item>
        <el-form-item label="审批说明" prop="approveNote" v-else :rules="{required:true,message:'请填写审批说明',trigger:'blur'}" style="margin-bottom:5px">
          <el-input v-model="planForm.approveNote" maxlength="999" :disabled="!boolean" show-word-limit type="textarea" :rows="4" placeholder="请输入审批说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" v-if="boolean">
        <el-button @click=" plandig= false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { performancePlanList, performanceApproval} from '@/api/performance';
export default {
  props: {
    searchForm: {
      required: true,
      type: Object
    },
    table: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      columns: [
        { id: 'staffName', label: '姓名' },
        { id: 'performanceName', label: '绩效考核名称' },
        { id: 'assessCycle', label: '绩效计划周期' },
        { id: 'assessStartTime', label: '开始时间' },
        { id: 'assessEndTime', label: '结束时间' },
        { id: 'contentName', label: '附件', minWidth: '180px'},
        { id: 'statusName', label: '状态' },
        { id: 'updateTime', label: '操作时间', minWidth: '120px' }
        // { id: "operation", label: "操作", text: "审批" }
      ],
      plandig: false,
      boolean: true,
      tableData: [],
      planForm: {
        id: '',
        approvalStatus: 0,
        approveNote: '',
        staffName: '',
        jobNumber: '',
        orgName: '',
        officeName: '',
        repObjName: ''
      }
    };
  },
  mounted() {
    this.getPerformancePlan();
  },
  methods: {
    submit() {
      this.$refs.planForm.validate((valid) => {
        if (valid) {
          this.$confirm(`${this.planForm.approvalStatus == '1' ? '您确认要通过当前绩效计划?' : '您确认不通过当前绩效计划?'}`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            performanceApproval({approvalStatus: this.planForm.approvalStatus, approveNote: this.planForm.approveNote, userPerformanceId: this.planForm.id}).then((res) => {
              if (res._responseStatusCode == 0) {
                  this.$message.success('绩效审批已提交');
                  this.getPerformancePlan();
                  this.plandig = false;
              }
            });
          });
        }
      });
    },
    change(val) {
      if (val == '1') {this.$refs.planForm.clearValidate('approveNote');}
    },
    returnStatusColor(status) {
      switch (Number(status)) {
          case 1: return '#409EFF';
          case 2: return '#FEB840';
          case 3: return '#64C940';
          case 4: return '#FF604D';
          case 5: return '#40CEFC';
          case 6: return '#F38A41';
          case 7: return '#FF604D';
          case 8: return '#F38A41';
          case 9: return '#FF604D';
          case 10: return '#64C940';
      }
    },
    openNote(row) {
      if (row.status != '2') {
          this.$alert(`${row.approveNote}`, '状态说明', {
          confirmButtonText: '确定'
        });
      }
    },
    openFile(file) {
      const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(JSON.stringify({fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl}))}`;

      window.open(url);
    },
    getPerformancePlan() {
      const params = {
        ...this.searchForm,
        type: '1',
        pageNo: this.table.pageNo,
        pageSize: this.table.pageSize
      };

      this.$emit('loading', true);
      performancePlanList(params).then((res) => {
        if (res.list) {
          this.tableData = res.list;
          this.$emit('pagegive', res.totalNum);
        }
        this.$emit('loading', false);
      });
    },
    Aduit(boolean, row) {
      this.boolean = boolean;
      if (this.$refs.planForm !== undefined) {
        this.$refs['planForm'].resetFields();
        this.planForm = {
          id: '',
          approvalStatus: 0,
          approveNote: '',
          staffName: '',
          jobNumber: '',
          orgName: '',
          officeName: '',
          repObjName: ''
        };
      }
      for (const key in this.planForm) {
        for (const key1 in row) {
          if (key == key1 && row[key1]) {
            this.planForm[key] = row[key1];
          }
        }
      }
      this.planForm.approvalStatus = row.status == '3' ? '1' : '0';
      if (boolean && row.status == '2') {this.planForm.approveNote = '';}
      this.plandig = true;
    }
  }
};
</script>

<style lang="scss">
</style>
