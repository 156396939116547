<template>
  <div class="achievementplan">
    <el-table :data="tableData" stripe header-row-class-name="tableClass">
      <el-table-column
        v-for="col in columns"
        align="center"
        :prop="col.id"
        :key="col.id"
        :min-width="col.minWidth"
        :label="col.label"
      >
        <template slot-scope="{row}">
          <div v-if="col.id=='staffName'">{{row.staffName}}({{row.jobNumber}})</div>
          <div v-else-if="col.id=='assessmentNote'" class="noteBox">{{row.assessmentNote}}</div>
          <el-button
            v-else-if="col.id=='contentName'&&row.contentFileUrlList"
            type="text"
            class="filebtn"
            @click="openFile(row.contentFileUrlList[0]?row.contentFileUrlList[0]:'')"
          >{{row.contentFileUrlList[0]?row.contentFileUrlList[0].name:''}}</el-button>
          <div :style="`color:${returnStatusColor(row.status)}`" v-else-if="col.id=='statusName'" @click="openNote(row)">{{row.statusName}}</div>
          <!--<el-button type="text" v-else-if="col.id=='operation'&&row.status!='2'" @click="opendig(row)">{{col.text}}</el-button>!-->
          <div v-else>{{row[col.id]}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="operation" align="center">
        <template slot-scope="{row}">
          <el-button type="text" v-if="(row.status=='1'||row.status=='4')&&row.startStatus=='1'" @click="opendig(row,true)">填写</el-button>
          <el-button type="text" v-if="(row.status!='1'&&row.status!='4')" @click="opendig(row,false)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      columns: [
        { id: 'staffName', label: '姓名' },
        { id: 'performanceName', label: '绩效考核名称' },
        { id: 'assessCycle', label: '绩效计划周期' },
        { id: 'assessStartTime', label: '开始时间' },
        { id: 'assessEndTime', label: '结束时间' },
        { id: 'contentName', label: '附件', minWidth: '180px'},
        { id: 'statusName', label: '状态' },
        { id: 'updateTime', label: '操作时间', minWidth: '120px' }
        // { id: "operation", label: "操作", text: "填写" }
      ]
    };
  },
  methods: {
    opendig(row, boolean) {
      this.$emit('opendig', {row, boolean});
    },
    openFile(file) {
      const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(JSON.stringify({fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl}))}`;

      window.open(url);
    },
    returnStatusColor(status) {
      switch (Number(status)) {
        case 1: return '#409EFF';
        case 2: return '#FEB840';
        case 3: return '#64C940';
        case 4: return '#FF604D';
        case 5: return '#40CEFC';
        case 6: return '#F38A41';
        case 7: return '#FF604D';
        case 8: return '#F38A41';
        case 9: return '#FF604D';
        case 10: return '#64C940';
      }
    },
    openNote(row) {
      if (row.status == '3' || row.status == '4') {
         this.$alert(`${row.approveNote}`, '状态说明', {
          confirmButtonText: '确定'
        });
      }
    }
  }
};
</script>

<style lang="scss">
.achievementplan {
  margin-top:20px ;
}
</style>
