<template>
  <el-table :data="tableData" stripe header-row-class-name="tableClass">
      <el-table-column
        v-for="col in columns"
        align="center"
        :prop="col.id"
        :key="col.id"
        :min-width="col.minWidth"
        :label="col.label"
      >
      </el-table-column>
    </el-table>
</template>
<script>
import { performanceTutorship} from '@/api/performance';
export default {
    props: {
      searchForm: {
        required: true,
        type: Object
      },
      table: {
        required: true,
        type: Object
      }
    },
    data() {
        return {
          columns: [
            { id: 'tutorshipTime', label: '绩效辅导时间' },
            { id: 'tutorshipStaffName', label: '辅导人' },
            { id: 'performanceName', label: '绩效考核模板' },
            { id: 'tutorshipContent', label: '辅导内容', minWidth: '300px' }
          ],
          tableData: []
        };
    },
    mounted() {
      this.getTutorshipList();
    },
    methods: {
      getTutorshipList() {
        const params = {
          ...this.searchForm,
          startTime: this.searchForm.timeArr !== null ? this.searchForm.timeArr[0] : '',
          endTime: this.searchForm.timeArr !== null ? this.searchForm.timeArr[1] : '',
          pageNo: this.table.pageNo,
          pageSize: this.table.pageSize
        };

        this.$emit('loading', true);
        performanceTutorship(params).then((res) => {
          this.tableData = res.list;
          this.$emit('pagegive', res.totalNum);
          this.$emit('loading', false);
        });
      }
    }
};
</script>

<style>

</style>
