<template>
  <div>
    <el-table :data="tableData" stripe header-row-class-name="tableClass">
      <el-table-column
        v-for="col in columns"
        align="center"
        :prop="col.id"
        :key="col.id"
        :show-overflow-tooltip="col.id=='assessmentNote'"
        :min-width="col.minWidth"
        :label="col.label"
      >
      <template slot-scope="{row}">
        <div v-if="col.id=='staffName'">{{row.staffName}}({{row.jobNumber}})</div>
        <div v-else-if="col.id=='assessmentNote'" class="noteBox">
            {{row.assessmentNote}}
        </div>
        <el-button
            v-else-if="col.id=='contentName'&&row.contentFileUrlList"
            type="text"
            class="filebtn"
            @click="openFile(row.contentFileUrlList[0]?row.contentFileUrlList[0]:'')"
          >{{row.contentFileUrlList[0]?row.contentFileUrlList[0].name:''}}</el-button>
          <div :style="`color:${returnStatusColor(row.status)}`" v-else-if="col.id=='statusName'" @click="openNote(row)">{{row.statusName}}</div>
        <div v-else>{{row[col.id]}}</div>
      </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row}">
            <el-button type="text"  v-if="row.startStatus=='1'&&row.status!='8'&&row.status!='7'" @click="openDig(true,'0',row)">不通过</el-button>
            <el-button type="text"  v-if="row.startStatus=='1'&&row.status!='8'&&row.status!='7'" @click="openDig(true,'1',row)">评定</el-button>
            <el-button type="text" v-if="(row.startStatus=='1'&&row.status=='8'||row.status=='7')||row.startStatus!='1'" @click="openDig(false,null,row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="绩效评定" :visible.sync="plandig" width="40%" class="plandig">
      <div class="losetitle">
        <strong>{{ planForm.staffName }}({{planForm.jobNumber}})</strong>
        <div style="overflow:hidden">
          <div class="fl" style="line-height:20px">部门：{{ planForm.orgName }} 职位：{{ planForm.officeName }}</div>
          <div class="fr" style="line-height:20px">直接汇报人：{{ planForm.repObjName }}</div>
        </div>
      </div>
      <p class="border-line"></p>
      <el-form :model="planForm" ref="planForm" label-width="100px" style="padding:10px 0">
        <el-form-item label="不通过说明" v-if="type=='0'"  prop="assessmentNote" :rules="{required:true,message:'请填写不通过说明',trigger:'blur'}">
          <el-input v-model="planForm.assessmentNote" maxlength="999" :disabled="!boolean" show-word-limit type="textarea" :rows="4" placeholder="请输入不通过说明"></el-input>
        </el-form-item>
        <el-form-item label="绩效确认人" v-if="type=='1'" prop="assessResultConfirmId" :rules="rule">
          <el-select v-model="planForm.assessResultConfirmId" placeholder :disabled="!boolean" style="width:90%" filterable>
            <el-option
              v-for="item in dutyUsers"
              :disabled="!$store.state.app.companyPrin&&($store.state.app.staffId==item.value)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绩效等级"  v-if="type=='1'" prop="performanceScoreUser" :rules="{required:true,message:'请选择评定绩效等级',trigger:'change'}">
          <el-select v-model="planForm.performanceScoreUser" :disabled="!boolean" placeholder="请选择对应绩效等级">
            <el-option v-for="(item,i) in levelArr"
              :key="i"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评价说明"  v-if="type=='1'" prop="assessmentNote" :rules="{required:true,message:'请填写评价说明',trigger:'blur'}">
          <el-input v-model="planForm.assessmentNote" :disabled="!boolean" maxlength="999" show-word-limit type="textarea" :rows="4" placeholder="请输入评价说明" style="width:90%"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" v-if="boolean">
        <el-button @click=" plandig= false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { performancePlanList, performanceScoreList, performanceAssessEvaluate, AdminList} from '@/api/performance';

export default {
    props: {
      searchForm: {
        required: true,
        type: Object
      },
      table: {
        required: true,
        type: Object
      }
    },
    data() {
      const assessResultConfirmId = (rule, value, callback) => {
        if (!value || value == 0) {
          return callback(new Error('请选择绩效评定人'));
        }
          callback();

      };

        return {
            columns: [
                { id: 'staffName', label: '姓名'},
                { id: 'performanceName', label: '绩效考核名称'},
                { id: 'assessCycle', label: '绩效计划周期' },
                { id: 'assessStartTime', label: '开始时间' },
                { id: 'assessEndTime', label: '结束时间' },
                { id: 'contentName', label: '附件', minWidth: '160px'},
                { id: 'statusName', label: '状态' },
                { id: 'performanceScoreUser', label: '绩效等级' },
                { id: 'assessmentNote', label: '评价说明', minWidth: '160px'},
                { id: 'updateTime', label: '操作时间'}
            ],
            dutyUsers: [],
            levelArr: [],
            tableData: [],
            rule: {required: true, validator: assessResultConfirmId, trigger: 'change'},
            planForm: {assessResultConfirmId: '', performanceScoreUser: '', assessmentNote: '', checkAssessDistributed: ''},
            type: '0',
            plandig: false,
            boolean: true
        };
    },
    mounted() {
      this.getPerformancePlan();
    },
    methods: {
      submit() {
        this.$refs.planForm.validate((valid) => {
          if (valid) {
            this.$confirm(`${this.type == '1' ? '您确认提交当前绩效评定?' : '您确认不通过当前绩效计划自评?'}`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              const params = {
                userPerformanceId: this.planForm.id,
                evaluateStatus: this.type,
                assessResultConfirmId: this.planForm.assessResultConfirmId,
                performanceScoreUser: this.planForm.performanceScoreUser,
                assessmentNote: this.planForm.assessmentNote,
                checkAssessDistributed: this.planForm.checkAssessDistributed
              };

              performanceAssessEvaluate(params).then((res) => {
                if (res._responseStatusCode == 0 && !res.tipMessage) {
                  this.$message.success('绩效评定已提交');
                  this.getPerformancePlan();
                  this.plandig = false;
                } else if (res.tipMessage) {
                  this.$confirm(res.tipMessage, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    params.checkAssessDistributed = 'N';
                    performanceAssessEvaluate(params).then((res) => {
                      if (res._responseStatusCode == 0 && !res.tipMessage) {
                        this.$message.success('绩效评定已提交');
                        this.getPerformancePlan();
                        this.plandig = false;
                      }
                    });
                  });
                }
              });
            });
          }
        });
      },
      openFile(file) {
      const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(JSON.stringify({fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl}))}`;

      window.open(url);
      },
      returnStatusColor(status) {
        switch (Number(status)) {
            case 1: return '#409EFF';
            case 2: return '#FEB840';
            case 3: return '#64C940';
            case 4: return '#FF604D';
            case 5: return '#40CEFC';
            case 6: return '#F38A41';
            case 7: return '#FF604D';
            case 8: return '#F38A41';
            case 9: return '#FF604D';
            case 10: return '#64C940';
        }
      },
      getPerformancePlan() {
        const params = {
          ...this.searchForm,
          type: '2',
          pageNo: this.table.pageNo,
          pageSize: this.table.pageSize
        };

        this.$emit('loading', true);
        performancePlanList(params).then((res) => {
          if (res.list) {
            this.tableData = res.list;
            this.$emit('pagegive', res.totalNum);
          }
          this.$emit('loading', false);
        });
      },
      openDig(boolean, type, row) {
        Object.assign(this.planForm, row);
        this.boolean = boolean;
        if (boolean) {this.planForm.assessmentNote = '';}
        this.planForm.assessResultConfirmId = String(this.planForm.assessResultConfirmId);
        this.type = type ? type : row.performanceScoreUser ? '1' : '0';
        if (type == '1' || !boolean) {
          this.dutyUsers = [];
          AdminList({}).then((res) => {
            if (Array.isArray(res)) {
              res.map((it) => {
                this.dutyUsers.push({
                  label: it.name,
                  value: it.id
                });
              });
            }
          });
          this.levelArr = [];
          performanceScoreList({performanceId: row.performanceId, screenType: '2'}).then((res) => {
            if (res.list) {
              this.levelArr = res.list;
            }
          });
        }
        this.plandig = true;
        if (this.$refs.planForm !== undefined) {
          this.$refs.planForm.clearValidate();
        }
      },
      openNote(row) {
        if (row.status != '8' && row.status != '6') {
          this.$alert(`${Number(row.status) > 8 ? row.confirmNote : row.assessmentNote}`, '状态说明', {
            confirmButtonText: '确定'
          });
        }
      }
    }
};
</script>

<style>

</style>
