<template>
  <div>
    <el-table :data="tableData" stripe header-row-class-name="tableClass">
      <el-table-column
        v-for="col in columns"
        align="center"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :min-width="col.minWidth"
      >
        <template slot-scope="{row}">
          <div v-if="col.id=='staffName'">{{row.staffName}}({{row.jobNumber}})</div>
          <el-button
            v-else-if="col.id=='contentName'&&row.contentFileUrlList"
            type="text"
            class="filebtn"
            @click="openFile(row.contentFileUrlList[0]?row.contentFileUrlList[0]:'')"
          >{{row.contentFileUrlList[0]?row.contentFileUrlList[0].name:''}}</el-button>
          <div v-else>{{row[col.id]}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{row}">
          <el-button type="text" v-if="row.selfEvaluationStatus=='0'&&row.approveStatus=='3'" @click="opendig(row)">辅导</el-button>
          <el-button type="text" @click="$router.push({name:'AssesstutorshipInfo',params:{staffId:row.staffId||'0',obj:JSON.stringify(row)}})">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="绩效辅导" :visible.sync="plandig" center width="40%" class="plandig">
      <div class="losetitle">
        <strong>{{ planForm.staffName }}({{planForm.jobNumber}})</strong>
        <div style="overflow:hidden">
          <div>部门：{{ planForm.orgName }} &nbsp;&nbsp;职位：{{ planForm.officeName }}</div>
          <div>
            <div class="fl" style="line-height:20px">考核周期：{{ planForm.assessStartTime }}至{{planForm.assessEndTime}}</div>
            <div class="fr" style="line-height:20px">绩效考核名称：{{planForm.performanceName}}</div>
          </div>
        </div>
      </div>
      <p class="border-line"></p>
      <el-form :model="planForm" ref="planForm" label-width="80px" style="padding:10px 0">
        <el-form-item label="辅导内容" prop="tutorshipContent" :rules="{required:true,message:'请输入辅导内容',trigger:'blur'}" style="margin-bottom:10px">
          <el-input
            v-model="planForm.tutorshipContent"
            maxlength="999"
            show-word-limit
            type="textarea"
            :rows="4"
            placeholder="请输入辅导内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click=" plandig= false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { performanceTutorshipList, tutorshipSubmit} from '@/api/performance';
export default {
  props: {
    searchForm: {
      required: true,
      type: Object
    },
    table: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      columns: [
        { id: 'staffName', label: '姓名' },
        { id: 'performanceName', label: '绩效考核名称', minWidth: '125px'},
        { id: 'assessCycle', label: '绩效计划周期' },
        { id: 'assessStartTime', label: '开始时间' },
        { id: 'assessEndTime', label: '结束时间' },
        { id: 'contentName', label: '附件', minWidth: '120px'},
        { id: 'tutorshipNumber', label: '次数' }
      ],
      plandig: false,
      loading: false,
      tableData: [],
      planForm: {tutorshipContent: ''}
    };
  },
  mounted() {
    this.getTutorshipList();
  },
  methods: {
    submit() {
        this.$refs.planForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            tutorshipSubmit({userPerformanceId: this.planForm.userPerformanceId, tutorshipContent: this.planForm.tutorshipContent}).then((res) => {
              if (res._responseStatusCode == 0) {
                  this.$message.success('绩效辅导已提交');
                  this.getTutorshipList();
                  this.plandig = false;
                }
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            });
          }
        });
    },
    opendig(row) {
      this.planForm = row;
      this.plandig = true;
      if (this.$refs.planForm !== undefined) {
        this.$refs['planForm'].clearValidate();
        this.$refs['planForm'].resetFields();
      }
    },
    openFile(file) {
      const url = `${location.origin}/v1/common/downLoad?exportparam=${encodeURIComponent(JSON.stringify({fileName: file.name, bucketName: file.bucketName, key: file.key, httpUrl: file.httpUrl}))}`;

      window.open(url);
    },
    getTutorshipList() {
      const params = {
        ...this.searchForm,
        pageNo: this.table.pageNo,
        pageSize: this.table.pageSize
      };

      this.$emit('loading', true);
      performanceTutorshipList(params).then((res) => {
        if (res) {
          this.tableData = res.list;
          this.$emit('pagegive', res.totalNum);
          this.$emit('loading', false);
        }
      });
    }
  }
};
</script>

<style>
</style>
